var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"edu-product"},[_vm._m(0),_c('div',{staticClass:"paragraphs"},[_c('div',{staticClass:"productTab"},[_c('div',{staticClass:"tabs title"},[_c('span',{class:_vm.isActive==0? 'active' : '',on:{"click":function($event){_vm.isActive =0}}},[_vm._v("智能计算加速卡系列")]),_c('span',{class:_vm.isActive==1? 'active' : '',on:{"click":function($event){_vm.isActive =1}}},[_vm._v("边缘智能计算机系统")]),_c('span',{class:_vm.isActive==2? 'active' : '',on:{"click":function($event){_vm.isActive =2}}},[_vm._v("南京智算中心云平台")]),_c('span',{class:_vm.isActive==3? 'active' : '',on:{"click":function($event){_vm.isActive =3}}},[_vm._v("智算中心云储存")])]),_c('div',{staticClass:"productChildren",class:_vm.isActive!==3 ?'description':'smallDescription'},_vm._l((_vm.productChildren),function(item,index){return _c('div',{key:index},[(_vm.isActive==index)?_c('div',{staticStyle:{"position":"relative"}},_vm._l((item.spanList),function(it,ind){return _c('div',{key:ind,staticClass:"types"},[_c('div',[_c('div',{staticClass:"left title",class:it.active?'activeTitle':'',on:{"mouseleave":function($event){item.spanList.forEach(j => {
                                                j.active = false;
                                                });it.active = true},"mousemove":function($event){return item.spanList.forEach(j => {
                                                j.active = false;
                                                it.active = true;
                                            })}}},[_vm._v(" "+_vm._s(it.name)+" ")]),(it.active)?_c('div',{staticClass:"right"},_vm._l((it.imgList),function(el,i){return _c('div',{key:i,staticClass:"image"},[_c('div',{style:({ 'backgroundImage': `url(${el.src})` ,'background-repeat':'no-repeat','background-position':'center','background-size':'cover','height':'100%'})})])}),0):_vm._e()]),(it.active)?_c('div',{staticClass:"introduction"},[_c('p',{staticClass:"title borderLine"},[_vm._v("产品说明")]),_c('p',[_vm._v(_vm._s(it.name))]),_c('p',{domProps:{"innerHTML":_vm._s(it.productIntorduce)}}),_c('div',{staticClass:"spec"},[_c('p',{staticClass:"title borderLine"},[_vm._v("技术规格")]),_c('table',_vm._l((it.data),function(tableData){return _c('tr',{key:tableData.index},[_c('td',{staticClass:"table-label"},[_vm._v(_vm._s(tableData.label))]),_c('td',{staticClass:"table-info"},[_vm._v(_vm._s(tableData.info))])])}),0)])]):_vm._e()])}),0):_vm._e()])}),0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"relative"}},[_c('img',{staticClass:"bannerImg",attrs:{"src":require("../assets/images/edu_products/banner.png"),"alt":""}}),_c('div',{staticClass:"banner"},[_c('p',{staticClass:"title"},[_vm._v("人工智能教学硬件")]),_c('p',[_vm._v("国产人工智能教育科研解决方案")]),_c('p',[_vm._v("为建设国产人工智能教育科研平台、普及国产智能计算平台框架，推动国产智能处理器在科学与工程研究项目中的广泛使用。智算中心运营团队从实际案例出发，研发出了完善的国产边缘智能教学套件，包含了完整的软硬件系统、内容与服务。")])])])
}]

export { render, staticRenderFns }