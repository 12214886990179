<template>
    <div class="edu-product">
         <div style="position:relative">
          <img class="bannerImg" src="../assets/images/edu_products/banner.png" alt="">
        <div class="banner">
            <p class="title">人工智能教学硬件</p>
            <p>国产人工智能教育科研解决方案</p>
            <p>为建设国产人工智能教育科研平台、普及国产智能计算平台框架，推动国产智能处理器在科学与工程研究项目中的广泛使用。智算中心运营团队从实际案例出发，研发出了完善的国产边缘智能教学套件，包含了完整的软硬件系统、内容与服务。</p>
        </div>
        </div>
        <div class="paragraphs">
            <div class="productTab">
                <div class="tabs title">
                    <span :class="isActive==0? 'active' : ''"  @click="isActive =0">智能计算加速卡系列</span>
                    <span :class="isActive==1? 'active' : ''"  @click="isActive =1">边缘智能计算机系统</span>
                    <span :class="isActive==2? 'active' : ''"  @click="isActive =2">南京智算中心云平台</span>
                    <span :class="isActive==3? 'active' : ''"  @click="isActive =3">智算中心云储存</span>
                </div>
                <div class="productChildren"  :class="isActive!==3 ?'description':'smallDescription'">
                        <div v-for="(item,index) of productChildren" :key="index" >
                            <div v-if="isActive==index" style=" position: relative" >
                                   <div v-for="(it,ind) of item.spanList" :key="ind"  class="types">
                                     <div>
                                            <div class="left title"  
                                               :class="it.active?'activeTitle':''"
                                                @mouseleave="item.spanList.forEach(j => {
                                                    j.active = false;
                                                    });it.active = true"
                                                @mousemove="item.spanList.forEach(j => {
                                                    j.active = false;
                                                    it.active = true;
                                                })">
                                                {{it.name}}
                                        </div>   
                                        <div class="right"  v-if="it.active">
                                                <div v-for="(el,i) of it.imgList" :key="i" class="image" >
                                                    <div  :style="{ 'backgroundImage': `url(${el.src})` ,'background-repeat':'no-repeat','background-position':'center','background-size':'cover','height':'100%'}"></div>
                                                </div>
                                            </div>  
                                        </div>
                                       <div class="introduction" v-if="it.active" >
                                         <p class="title borderLine" >产品说明</p>
                                         <p>{{it.name}}</p>
                                         <p v-html="it.productIntorduce"></p>
                                         <div class="spec" >
                                         <p class="title borderLine">技术规格</p>
                                           <table>
                                            <tr v-for="tableData in it.data" :key="tableData.index">
                                                <td class="table-label">{{ tableData.label }}</td>    
                                                <td class="table-info">{{ tableData.info }}</td>    
                                            </tr>
                                        </table>
                                     </div>
                                 </div>  
                             </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import newsData from '@/assets/js/news.js'
export default {
    name: 'eduHardware',
    data () {
        return {
            isActive: 0,
            productChildren: newsData.productList,
            
        }
    },
    mounted() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
    methods: {
      
    }
}
</script>
<style lang="scss" scoped>
.edu-product {
 .banner{
     left:10%;
     margin-right: 45%;
 }
    .paragraphs{
           .title{
               font-size: 0.2rem;
               font-weight: 400;
               color: #010101;
           }
        .productTab {
            .tabs {
                margin-bottom: 0.4rem;
                display: flex;
                justify-content: space-between;
                span {
                    display: inline-block;
                    width: 30%;
                    height: 0.64rem;
                    background: #FFF;
                    text-align: center;
                    border: 1px solid #0061E3;
                    border-left:none;
                    cursor: pointer;
                    color: #0061E3;
                    line-height: 0.6rem;
                    font-size: 0.24rem;
                }
                span:nth-child(1){
                  border: 1px solid #0061E3;  
                }
                .active {
                    background: #0061E3;
                    color: #FFFFFF;
                }
                
            }
        }
        .introduction{
            font-size: 0.18rem;
            font-weight: 400;
            color: #666;
            line-height: 0.35rem;
        }
        // .productChildren{
        //     overflow: hidden;
        // }
        .description{
            height:10rem;
        }
        .smallDescription{
            height: 6rem;
        }
        .types{
                .left{
                    width: 20%;
                    height: 0.8rem;
                    line-height: 0.8rem;
                    cursor: pointer;
                }
                .activeTitle{
                 background-color:  #F0F0F0;
                 color:#0061E3
                }
                .left:hover{
                     background-color:  #F0F0F0;
                     color:#0061E3
                }
                .right{
                    width: 80%;
                    display: flex;
                    height: 2.4rem;
                    background-color:  #F0F0F0;
                    justify-content: space-around;
                    align-items: center;
                    position: absolute;
                    top:0;
                    right: 0;
                    .image{
                      width: 30%;
                      height: 60%;
                }
                }
                .introduction{
                   position: absolute;
                   top:2.5rem;
                   .borderLine{
                    margin-top: 0.2rem;
                     display: inline-block;
                     border-top:1px solid #0061E3 ;
                   }
                }
                 table{
                    margin:0.2rem auto;
                    width:100%;
                }
                td{
                    border: 1px solid #DCDFE6;
                    padding:0 0.3rem;
                }
                 .table-label{
                    height: 0.5rem;
                    line-height:0.5rem;
                    background: #f7f7f7;
                    font-weight: 600;
                    color:#303133;
                }
        }
        
        
    }
}

</style>
